<template>
  <div class="natural lighten-4 fill-height">
    <SingleAppBar title="ตั้งค่า" :backward="false"/>
    <v-main>
      <v-container fluid class="pa-0">
          <v-list
      one-line
    >
     <v-list-item>
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  การแจ้งเตือน
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action>
               <v-switch inset></v-switch>
           </v-list-item-action>
     </v-list-item>
     <v-divider/>
      <v-list-item :to="'/setting/lang'">
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  ภาษาที่เลือก
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
               ภาษาไทย (TH)
           </v-list-item-action>
     </v-list-item>
      <v-divider/>
       <v-list-item >
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  เวอร์ชั่นปัจจุบัน
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
              {{version}}
           </v-list-item-action>
     </v-list-item>
      <v-divider/>
       <v-list-item @click="confirmDialog=true">
          <v-list-item-content>
              <v-list-item-title class="text-body-large">
                  ออกจากระบบ
              </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action class="text-body-large">
       
           </v-list-item-action>
     </v-list-item>
     
    </v-list>
      </v-container>
      <v-dialog v-model="confirmDialog" persistent width="327">
<ConfirmCard :title="'คุณต้องการออกจากระบบหรือไม่'" @onClose="confirmDialog=false" @onConfirm="signOut"/>
      </v-dialog>
   
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";
import ConfirmCard from "@/components/common/ConfirmCard"

export default {
  name: "Home",
  components: {
    SingleAppBar,
    ConfirmCard
  },
  methods: {
     signOut() {
      this.$offlineStorage.set('isAuthen', false)
      this.$router.push({name: 'signin'})
    }
  },
  data() {
    return {
     version: process.env.VUE_APP_VERSION,
     confirmDialog: false
    }
  },
};
</script>
